<template>
  <v-expansion-panel class="" v-if="hasReferences()">
    <v-expansion-panel-header
      >Gerelateerde documenten (bèta)</v-expansion-panel-header
    >
    <v-expansion-panel-content>
      <v-timeline v-if="items" :reverse="false" dense align-top>
        <v-timeline-item
          v-for="item in items.data"
          :key="item.id"
          :color="
            codeToColor(
              item.type &&
                (item.type.startsWith('EUROPARL-') ||
                  item.type.startsWith('EUROCOM-') ||
                  item.type.startsWith('EUROCOU-'))
                ? item.institution
                : item.type
            )
          "
          :icon="`${id && id === item.id ? 'mdi-disc' : ''}`"
          :small="id && id !== item.id"
        >
          <v-card
            class="elevation-2"
            @click.stop.prevent="goToUrlConditionally(item.type, item.id)"
          >
            <v-card-title class="text-subtitle-2 mb-n2">
              {{ formattedDate(item.publicationDate) }}
            </v-card-title>
            <v-card-text class="mt-n2">
              {{ itemStripHtml(item.title) }}
              <span v-if="item.fragDocu">
                {{ itemFragDocufy(item.fragDocu) }}</span
              >
            </v-card-text>
            <v-card-text class="text-caption mt-n5">
              {{ getCleanedRef(item) }}
              <span v-if="getProcedureNumber(item)">
                <span class="font-weight-bold">en</span>
                {{ getProcedureNumber(item) }}
              </span>
              -
              <span class="font-weight-bold"
                >{{ codeToName(item.institution) }}
                <span v-if="item.url">
                  {{ itemUrlToSourceName(item.url) }}
                </span>
              </span>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from 'vuex';

import typeToColor from '@/helpers/colorHelper';
import urlToSourceName from '@/helpers/urlToSourceNameHelper';
import cleanReferenceNumber from '@/helpers/cleanReferenceNumberHelper';
import fragDocufy from '@/helpers/fragDocufyHelper';
import { stripHtml } from '@/helpers/toolbelt';

import { showDialog } from '@/helpers/dialogHelper';
import EuTeaserDialog from '@/modules/documentDashboard/components/EuTeaserDialog.vue';
import DutchSenateTeaserDialog from '@/modules/documentDashboard/components/DutchSenateTeaserDialog.vue';
import MissingModuleTeaserDialog from '@/modules/documentDashboard/components/MissingModuleTeaserDialog.vue';

export default {
  props: {
    refs: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: false,
    },
  },

  computed: {
    ...mapGetters({
      authHasModuleAccess: 'authHasModuleAccess',
      items: 'euDocumentRelated',
      userFilters: 'userFilters',
    }),

    cleanedRefs() {
      if (!this.refs || typeof this.refs !== 'object') return [];
      let cleanRefs = [];
      Object.values(this.refs).forEach((refType) => {
        cleanRefs = cleanRefs.concat(
          refType.map((ref) => cleanReferenceNumber(ref)),
        );
      });
      return cleanRefs;
    },
  },

  methods: {
    itemUrlToSourceName(url) {
      return urlToSourceName(url);
    },

    codeToColor(code) {
      return typeToColor(code);
    },

    itemStripHtml(itemTitle) {
      return stripHtml(itemTitle);
    },

    itemFragDocufy(fragDocu) {
      return fragDocufy(fragDocu);
    },

    hasReferences() {
      // References include the item itself.
      // We only show refs if OTHER references are found.
      return !!(this.items && this.items.data.length > 1);
    },

    // TODO: Have to map these items from an decent API call
    codeToName(code) {
      if (code === 'EC') {
        return 'Europese Commissie';
      }
      if (code === 'EP') {
        return 'Europees Parlement';
      }
      return '';
    },

    formattedDate(date) {
      return this.$moment(date).format('DD-MM-YYYY');
    },

    getCleanedRef(item) {
      // Expected case Europarl documents:
      if (item.reference) return cleanReferenceNumber(item.reference);

      // Expected case Tweede en Eerst Kamer documents:
      // Finds a reference the document has in common with the main document.
      // (metadata mentions only)
      // This is a bit hacky, and should be replaced when we start
      // returning the matched references from backend.
      let matchedRef;
      if (item?.metadata?.mentions?.length) {
        const matchedMention = item.metadata.mentions
          .find((mention) => this.cleanedRefs.includes(mention.term));
        matchedRef = matchedMention ? matchedMention.term : null;
      }
      return matchedRef;
    },

    getProcedureNumber(item) {
      const procedures = [];
      if (item.relations) {
        item.relations.forEach((relation) => {
          if (relation.type === 'PROCEDURE') {
            procedures.push(relation.code);
          }
        });
      }
      return procedures.toString().replace(',', ', ');
    },

    actionUrl(type, id) {
      const { nlGroupsByDocType, euGroupsByDocType, nlLrGroupsByDocType } = this.userFilters;
      let destination;
      if (nlGroupsByDocType[type]) destination = 'nl';
      else if (euGroupsByDocType[type]) destination = 'eu';
      else if (nlLrGroupsByDocType[type]) destination = 'nl-lr';
      else {
        throw new Error(
          `docType ${type} not found in any dashboard filter grouping`,
        );
      }
      return `${destination}/document/${id}`;
    },

    goToUrlConditionally(type, id) {
      const { documentTypesConfig } = this.userFilters;
      const { requiredModules } = documentTypesConfig[type];
      const missingModuleCodes = requiredModules
        .map(({ code }) => code)
        .filter((moduleCode) => !this.authHasModuleAccess(moduleCode));
      if (missingModuleCodes.length) {
        if (missingModuleCodes.includes('MC07')) showDialog(EuTeaserDialog);
        else if (missingModuleCodes.includes('MC02')) showDialog(DutchSenateTeaserDialog);
        else showDialog(MissingModuleTeaserDialog);
        return;
      }
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.$router.push({ path: this.actionUrl(type, id) });
      } else {
        window.open(this.actionUrl(type, id), '_blank');
      }
    },
  },

  mounted() {
    if (this.refs.europarl) {
      this.$store.dispatch('euGetRelated', {
        refs: this.refs,
      });
    }
  },
};
</script>

<style lang="scss" scoped></style>
