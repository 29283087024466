<template>
  <div class="">
    <div class='pdfViewer' ref="pdfViewer">
      <v-card
        class="pdfControls my-1 px-4"
        elevation="2"
        outlined
      >
        <div class="pdfPageCount">
          <v-icon
            size="22"
          >
            mdi-file-outline
          </v-icon>
          {{ numPages }} Pagina's
        </div>
        <v-spacer> </v-spacer>
        <v-spacer> </v-spacer>
        <v-btn
          icon
          @click="zoom('out')"
          :disabled="!zoomAllowed('out')"
        >
          <v-icon
            size="22"
          >
            mdi-magnify-minus
          </v-icon>
        </v-btn>
        <v-btn
          icon
          @click="zoom('in')"
          :disabled="!zoomAllowed('in')"
        >
          <v-icon
            size="22"
          >
            mdi-magnify-plus
          </v-icon>
        </v-btn>
        <v-btn
          v-if="['lg', 'xl'].includes($vuetify.breakpoint.name)"
          icon
          @click="toggleExpandedView()"
        >
          <v-icon
            size="22"
          >
            {{ expandedView ? 'mdi-arrow-expand-left' : 'mdi-overscan' }}
          </v-icon>
        </v-btn>
      </v-card>
      <div
        v-if="loading"
        class="spinner"
      >
        <v-progress-circular
          indeterminate
          class="ma-4"
        ></v-progress-circular>
      </div>

      <div
        class="zoomingIndicator"
        v-if="zooming || zoomLoading"
      >
        <div>
          <v-icon>
            mdi-magnify
          </v-icon>
          <v-progress-circular
            indeterminate
            class="mr-4"
            v-if="zoomLoading"
          ></v-progress-circular>
        </div>
      </div>

      <pdf-viewer-page
        v-show="!loading"
        v-for="i in numPages"
        :key="i"

        :ref="`pdfPage${i}`"
        :data-page-num="i"

        :pageNum="i"
        :pageDataPromise="document.getPage(i)"
        :zoomScale="zoomScale"
        :viewerWidth="viewerWidth"
        :keywords="keywords"
        :scopedAttribute="scopedAttribute"
        @pageReady="reemit"
      >
      </pdf-viewer-page>
    </div>
  </div>
</template>

<script>
import PdfViewerPage from './PDFViewerPage.vue';

const pdfjsLib = require('pdfjs-dist/build/pdf');
// The following file is imported as plain text rather than code.
const pdfjsLibWorkerRawText = require('pdfjs-dist/build/pdf.worker.min').default;

export default {
  components: {
    PdfViewerPage,
  },

  props: {
    pdf: {
      type: ArrayBuffer,
      required: false,
    },
    keywords: {
      type: Array,
      required: false,
    },
    expandedView: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      document: null,
      numPages: null,
      zoomScale: 1,
      zoomStepSize: 1.2,
      zoomScaleMax: 1.8,

      loading: true,
      rendersCount: 0,
      mounted: false,
      scopedAttribute: null,
      windowWidth: null,
      resizeTimeout: null,
      viewerWidth: null,
      intersectionObserver: null,

      startPinchDistance: null,
      zooming: false,
      zoomedThisPinch: false,
      zoomLoading: false,
    };
  },

  computed: {
    zoomScaleMin() {
      return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name)
        ? 0.3 // Allow zoom in
        : 1; // Don't allow zoom-in
    },
  },

  created() {
    // Magic to make the PDF.js worker work properly.
    pdfjsLib.GlobalWorkerOptions.workerSrc = URL.createObjectURL(new Blob([pdfjsLibWorkerRawText]));

    // Listen to window resize so we can redraw the pdf at the correct new size.
    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      const newWidth = window.innerWidth;
      // Only redraw if the width changed, not when only the height changes
      if (newWidth !== this.windowWidth) {
        this.windowWidth = newWidth;
        this.loading = true;
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(this.renderPdf, 500);
      }
    });
  },

  destroyed() {
    window.removeEventListener('resize', this.renderPdf);
  },

  mounted() {
    // Handle zoom events differently
    [
      'wheel',
      'touchstart', 'touchmove', 'touchend', 'touchcancel',
      'gesturestart', 'gesturechange', 'gestureend',
    ].forEach((event) => this.$refs.pdfViewer.addEventListener(event, this.zoomEvent));

    // Get the data-v-... attribute vue puts on components for scoped styling
    // so we can manually apply it to elements generated by pdf.js
    const viewerAttributes = Array.from(this.$refs.pdfViewer.attributes);
    this.scopedAttribute = viewerAttributes.map((attr) => attr.name)
      .find((attr) => attr && /data-v-[0-9a-f]+/.test(attr));

    this.mounted = true;
    this.loading = true;
    this.renderPdf();
  },

  methods: {
    zoomEvent(event) {
      // Zoom with ctrl-scroll: (and trackpad pinch ???)
      if (event.type === 'wheel' && event.ctrlKey) {
        // Prevent browser zoom
        event.preventDefault();
        event.stopPropagation();
        // Zoom PDF
        if (event.deltaY !== 0 && Math.abs(event.deltaY) > 5) {
          const inOrOut = event.deltaY < 0 ? 'in' : 'out';
          if (this.zoomAllowed(inOrOut)) this.zoom(inOrOut);
        }
      }

      // Detect zoom by pinch on mobile
      if (event.type === 'touchstart' && event.touches.length === 2) {
        this.zooming = true;
        this.zoomedThisPinch = false;
        this.startPinchDistance = Math.hypot( // Distance between fingers on screen
          event.touches[0].pageX - event.touches[1].pageX,
          event.touches[0].pageY - event.touches[1].pageY,
        );
      }
      if (event.type === 'touchmove' && event.touches.length === 2) {
        // Prevent browser zoom
        event.preventDefault();
        event.stopPropagation();
        if (!this.zoomedThisPinch) { // Don't zoom 2 or more levels in one pinch move
          const newPinchDistance = Math.hypot( // Distance between fingers on screen
            event.touches[0].pageX - event.touches[1].pageX,
            event.touches[0].pageY - event.touches[1].pageY,
          );
          const pinchDifference = newPinchDistance - this.startPinchDistance;
          const minimumDifference = (window.innerWidth / 15);
          if (Math.abs(pinchDifference) > minimumDifference) { // Don't zoom without some minimum movement of the fingers
            const inOrOut = pinchDifference > 0 ? 'in' : 'out';
            if (this.zoomAllowed(inOrOut)) this.zoom(inOrOut);
            this.zoomedThisPinch = true;
            this.zoomLoading = true;
          }
        }
      }
      if (event.type === 'touchend') {
        this.zooming = false;
        this.zoomLoading = false;
      }

      // Prevent these (Safari only) gesture events altogether for now.
      // Zooming with gestures in safari requires gesture events. https://developer.mozilla.org/en-US/docs/Web/API/GestureEvent
      if (/gesture(start|change|end)/.test(event.type)) {
        // Prevent browser zoom
        event.preventDefault();
        event.stopPropagation();
      }
    },
    reemit(...args) {
      this.$emit('pdfPageReady', ...args);
    },
    nextZoomScale(inOrOut) {
      if (inOrOut === 'in') return this.zoomScale / this.zoomStepSize;
      return this.zoomScale * this.zoomStepSize;
    },
    zoomAllowed(inOrOut) {
      const nextZoomScale = this.nextZoomScale(inOrOut);
      return nextZoomScale >= this.zoomScaleMin && nextZoomScale <= this.zoomScaleMax;
    },
    zoom(inOrOut) {
      this.zoomScale = this.nextZoomScale(inOrOut);
      this.renderPdf();
    },
    toggleExpandedView() {
      this.$emit('toggleExpandedView');
      this.zoomScale = 1;
      this.renderPdf();
    },

    async renderPdf() {
      if (!this.pdf) return;
      this.$emit('pdfRender');
      this.rendersCount += 1;
      const currRenderCount = this.rendersCount;

      this.document = await pdfjsLib.getDocument({
        data: this.pdf,
        // These cMap-params may be required for certain PDF's
        cMapUrl: '../../node_modules/pdfjs-dist/cmaps/',
        cMapPacked: true,

        // IMPORTANT! mitigates a vulnerability in PDF.js versions <4.2.67
        // https://codeanlabs.com/blog/research/cve-2024-4367-arbitrary-js-execution-in-pdf-js/
        // Documentation suggests this option is a performance improvement:
        // https://mozilla.github.io/pdf.js/api/draft/module-pdfjsLib.html#~DocumentInitParameters
        isEvalSupported: false,
      }).promise;
      this.viewerWidth = await this.getViewerWidth();
      // Underscore originates from PDF.js library
      // eslint-disable-next-line no-underscore-dangle
      this.numPages = this.document && this.document._pdfInfo.numPages;

      // Wait for nextTick (next update) as we have changed this.numPages, and need
      // to wait for Vue to render the page elements from the template.
      this.$nextTick(async () => {
        // Render page 1 as fast as possible
        const firstPageElem = this.$refs.pdfPage1[0];
        await firstPageElem.setCanvasDimentions();
        await firstPageElem.setBookmarks();
        await firstPageElem.render();

        // The intersectionObserver monitors when elements come into view.
        // Clear out potentialty existing observe-watchers first
        if (this.intersectionObserver) this.intersectionObserver.disconnect();
        this.intersectionObserver = new IntersectionObserver(
          this.onPageInView, // Callback called when elem overlaps with ...
          {
            root: null, // ... the viewport. (default)
            threshold: 0.01, // percentage of elem that should overlap (with the viewport)
          },
        );

        this.loading = false;

        // Scale canvas of other pages
        for (let pageNum = 2; pageNum <= this.numPages; pageNum++) {
          // Stop this render if a new render is started
          if (this.rendersCount !== currRenderCount) break;

          const pageElem = this.$refs[`pdfPage${pageNum}`][0];
          // We await in a loop as to not overload the browser.
          // eslint-disable-next-line no-await-in-loop
          await pageElem.setCanvasDimentions();

          // Register with intersectionObserver, to render when comming into view.
          this.intersectionObserver.observe(pageElem.$el);
        }

        // Set bookmarks of other pages
        for (let pageNum = 2; pageNum <= this.numPages; pageNum++) {
          // Stop this render if a new render is started
          if (this.rendersCount !== currRenderCount) break;

          const pageElem = this.$refs[`pdfPage${pageNum}`][0];
          // We await in a loop as to not overload the browser.
          // eslint-disable-next-line no-await-in-loop
          await pageElem.setBookmarks();
        }

        this.$emit('pdfRendered');
      });
    },

    // Callback when a page comes into view
    onPageInView(entries) {
      entries.filter((entry) => entry.isIntersecting).forEach(async (entry) => {
        const pageElem = entry.target;
        this.intersectionObserver.unobserve(pageElem); // Only draw page once.
        const pageNum = parseInt(pageElem.dataset.pageNum, 10);
        const vuePageElem = this.$refs[`pdfPage${pageNum}`][0];

        // Render page canvas
        await vuePageElem.render();
      });
    },

    async getViewerWidth() {
      // We wait till the browser has calulated a pixel value of the
      // css property: 'width: auto' of the viewer root element.
      let desiredWidthString = this.getViewerWidthCSSproperty();
      while (!desiredWidthString || desiredWidthString === 'auto') {
        // eslint-disable-next-line no-await-in-loop
        await new Promise((r) => setTimeout(r, 10)); // sleep 10ms
        desiredWidthString = this.getViewerWidthCSSproperty();
      }
      return parseFloat(desiredWidthString);
    },

    getViewerWidthCSSproperty() {
      if (!this.$refs.pdfViewer) return null;
      return window.getComputedStyle(this.$refs.pdfViewer, null).getPropertyValue('width');
    },
  },

  watch: {
    pdf() {
      if (this.mounted) {
        this.loading = true;
        this.renderPdf();
      }
    },
  },
};

</script>

<style scoped lang="scss">
  .pdfViewer {
    // Required to acurately calculate the width for the canvas with: window.getComputedStyle()
    box-sizing: content-box;

    .spinner {
      text-align: center;
    }

    .pdfControls {
      position: sticky;
      top: 66px; // A bit hacky: 64px (height site-header) + 2px as margin;
      display: flex;
      align-items: center;
      z-index: 2; // Draw on top of pages
    }

    @media only screen and (max-width: 960px) {
      .pdfControls {
        top: 58px; // A bit hacky: 56px (height site-header) + 2px as margin;
      }
    }

    .zoomingIndicator {
      position: fixed;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 3;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      pointer-events: none; // Prevent layer from making other element inaccessable

      div {
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: rgba(0, 0, 0, 0.39);
        border-radius: 2em;

        i {
            font-size: 6em;
        }
      }
    }
  }
</style>

<!-- Import styling from pdf.js library
We do some manual work PDFViewerpage to apply these
scoped styles to PDF.js generated elements. -->
<style scoped src="pdfjs-dist/web/pdf_viewer.css"></style>
