<template>
  <v-dialog v-model="internalShowDialog" max-width="1200px">
    <v-card>
      <v-card-title class="headline">Metadata</v-card-title>
      <v-card-text>
        <div class="d-flex justify-space-between">
          <div v-if="document?.metadata" class="metadata-section">
            <v-card-title class="headline">Original Metadata</v-card-title>

            <v-card-subtitle>Ministry Statistics</v-card-subtitle>
            <v-simple-table>
              <thead>
              <tr>
                <th class="text-left">Ministry</th>
                <th class="text-right">Total Value</th>
                <th class="text-right">Percentage</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(ministry, index) in document.metadata.ministeryStatistics.ministeries"
                  :key="index"
                  :class="getComparisonClass('ministry', ministry.name)"
              >
                <td>{{ ministry.name }}</td>
                <td class="text-right">{{ ministry.totalValue }}</td>
                <td class="text-right">{{ ministry.percentage }}%</td>
              </tr>
              </tbody>
            </v-simple-table>
            <v-divider class="my-3"></v-divider>

            <v-card-subtitle>Keyword Summary</v-card-subtitle>
            <v-simple-table>
              <thead>
              <tr>
                <th class="text-left">Keyword</th>
                <th class="text-right">Count</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(keyword, index) in document.metadata?.keywordSummary"
                  :key="index"
                  :class="getComparisonClass('keyword', keyword.keyword)"
              >
                <td>{{ keyword.keyword }}</td>
                <td class="text-right">{{ keyword.count }}</td>
              </tr>
              </tbody>
            </v-simple-table>
            <v-divider class="my-3"></v-divider>

            <v-card-subtitle>Mentions</v-card-subtitle>
            <v-simple-table>
              <thead>
              <tr>
                <th class="text-left">Term</th>
                <th class="text-right">Occurrences</th>
                <th class="text-left">Type</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(mention, index) in document.metadata?.mentions"
                  :key="index"
                  :class="getComparisonClass('mention', mention.term)"
              >
                <td>{{ mention.term }}</td>
                <td class="text-right">{{ mention.occurrences }}</td>
                <td>{{ mention.termType }}</td>
              </tr>
              </tbody>
            </v-simple-table>
          </div>

          <div v-if="document?.annotatorMetadata" class="metadata-section ml-5">
            <v-card-title class="headline">Annotator Metadata</v-card-title>

            <v-card-subtitle>Ministry Statistics</v-card-subtitle>
            <v-simple-table>
              <thead>
              <tr>
                <th class="text-left">Ministry</th>
                <th class="text-right">Total Value</th>
                <th class="text-right">Percentage</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(ministry, index) in document.annotatorMetadata?.ministeryStatistics?.ministeries"
                  :key="index"
                  :class="getComparisonClass('ministry', ministry.name)"
              >
                <td>{{ ministry.name }}</td>
                <td class="text-right">{{ ministry.totalValue }}</td>
                <td class="text-right">{{ ministry.percentage }}%</td>
              </tr>
              </tbody>
            </v-simple-table>
            <v-divider class="my-3"></v-divider>

            <v-card-subtitle>Keyword Summary</v-card-subtitle>
            <v-simple-table>
              <thead>
              <tr>
                <th class="text-left">Keyword</th>
                <th class="text-right">Count</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(keyword, index) in document.annotatorMetadata?.keywordSummary"
                  :key="index"
                  :class="getComparisonClass('keyword', keyword.keyword)"
              >
                <td>{{ keyword.keyword }}</td>
                <td class="text-right">{{ keyword.count }}</td>
              </tr>
              </tbody>
            </v-simple-table>
            <v-divider class="my-3"></v-divider>

            <v-card-subtitle>Mentions</v-card-subtitle>
            <v-simple-table>
              <thead>
              <tr>
                <th class="text-left">Term</th>
                <th class="text-right">Occurrences</th>
                <th class="text-left">Type</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(mention, index) in document.annotatorMetadata?.mentions"
                  :key="index"
                  :class="getComparisonClass('mention', mention.term)"
              >
                <td>{{ mention.term }}</td>
                <td class="text-right">{{ mention.occurrences }}</td>
                <td>{{ mention.termType }}</td>
              </tr>
              </tbody>
            </v-simple-table>
          </div>
        </div>
      </v-card-text>
      <v-card-subtitle>Legend</v-card-subtitle>
      <v-simple-table>
        <thead>
        <tr>
          <th class="text-left">Description</th>
          <th class="text-left">Style</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Present in one list but not the other</td>
          <td><span style="font-weight: bold; color: green">Bold Green</span></td>
        </tr>
        <tr>
          <td>Values differ by over 3% for ministry or 3 or more for keywords and mentions</td>
          <td><span style="font-weight: bold; color: orange">Bold Orange</span></td>
        </tr>
        <tr>
          <td>Values differ by over 15% for ministry or 15 or more for keywords and mentions</td>
          <td><span style="font-weight: bold; color: red">Bold Red</span></td>
        </tr>
        </tbody>
      </v-simple-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    document: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalShowDialog: this.value,
    };
  },
  watch: {
    value(newVal) {
      this.internalShowDialog = newVal;
    },
    internalShowDialog(newVal) {
      this.$emit('input', newVal);
    },
  },
  methods: {
    closeDialog() {
      this.internalShowDialog = false;
    },
    getComparisonClass(category, name) {
      const original = this.findInMetadata('metadata', category, name);
      const annotator = this.findInMetadata('annotatorMetadata', category, name);

      if (!original || !annotator) {
        return 'bold-green';
      }

      let originalValue;
      let annotatorValue;

      if (category === 'ministry') {
        originalValue = original.percentage;
        annotatorValue = annotator.percentage;
      }

      if (category === 'keyword') {
        originalValue = original.count;
        annotatorValue = annotator.count;
      }

      if (category === 'mention') {
        originalValue = original.occurrences;
        annotatorValue = annotator.occurrences;
      }

      const difference = Math.abs(originalValue - annotatorValue);

      if (difference > 15) {
        return 'bold-red';
      } if (difference > 2) {
        return 'bold-orange';
      }

      return '';
    },
    findInMetadata(type, category, name) {
      let list = [];

      if (category === 'ministry' && this.document[type]?.ministeryStatistics) {
        list = this.document[type]?.ministeryStatistics?.ministeries;
      } else if (category === 'keyword' && this.document[type]?.keywordSummary) {
        list = this.document[type]?.keywordSummary;
      } else if (category === 'mention' && this.document[type]?.mentions) {
        list = this.document[type]?.mentions;
      }

      return list.find((item) => item.name?.trim() === name.trim()
          || item.keyword?.trim() === name.trim()
          || item.term?.trim() === name.trim());
    },
  },
};
</script>

<style scoped>
.metadata-section {
  flex: 1;
  min-width: 300px;
  padding: 10px;
}

.bold-green {
  font-weight: bold;
  color: green;
}

.bold-red {
  font-weight: bold;
  color: red;
}

.bold-orange {
  font-weight: bold;
  color: orange;
}
</style>
